import { createRouter, createWebHistory } from 'vue-router';

import { useSessionStorage } from '@vueuse/core';
import { pickBy, merge } from 'lodash-es';
import { Userpilot } from 'userpilot';
import {
  BASE_DOMAIN,
  NODE_ENV,
} from '@/config/appConfig';

import useUserStore from '@/stores/user';
import usePermissionsStore from '@/stores/permissions';

import BaseLoggedInView from '@/views/BaseLoggedInView.vue';

const sessionStore = useSessionStorage('tagboard-app', {});

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'base',
      component: BaseLoggedInView,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/HomeView.vue'),
          children: [
            {
              path: '',
              name: 'dashboard',
              component: () => import('@/views/DashboardView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Creator Dashboard',
              },
            },
            {
              path: 'welcome',
              name: 'welcome',
              component: () => import('@/views/WelcomeView.vue'),
              // eslint-disable-next-line consistent-return
              beforeEnter: () => {
                // don't show upsells to users with a team or plan
                if (!useUserStore().isFreeAccountNoTeam) {
                  return { name: 'dashboard' };
                }
              },
            },
            {
              path: 'graphics',
              name: 'graphics',
              component: () => import('@/views/GraphicsView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Graphics',
                preserveQuery: {
                  params: ['tags'],
                },
              },
            },
            {
              // Use /graphic/create for a new graphic
              path: 'graphic/:graphicId',
              name: 'graphic-editor',
              component: () => import('@/views/GraphicEditorView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Graphic Editor',
              },
            },
            {
              path: 'social',
              name: 'social',
              component: () => import('@/views/SocialView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Social',
                preserveQuery: {
                  useHash: true,
                  params: ['tags'],
                },
              },
            },
            {
              // Use /display/create/:layout for a new display
              path: 'display/:displayId/:layout?',
              name: 'display-editor',
              component: () => import('@/views/DisplayEditorView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Display Editor',
              },
            },
            {
              path: 'board/:boardId/:tab?',
              name: 'board-editor',
              component: () => import('@/views/BoardEditorView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Board Editor',
              },
            },
            {
              path: 'interactive',
              name: 'interactive',
              component: () => import('@/views/InteractiveView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Interactive',
                preserveQuery: {
                  useHash: true,
                  params: ['tags'],
                },
              },
            },
            {
              // Use /panel/create/:type for a new panel
              path: 'panel/:panelId/:type?',
              name: 'panel-editor',
              component: () => import('@/views/PanelEditorView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Panel Editor',
              },
            },
            {
              // Use /embed/create/:type for a new embed
              path: 'embed/:embedId/:type?',
              name: 'embed-editor',
              component: () => import('@/views/EmbedEditorView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Embed Editor',
              },
            },
            {
              path: 'settings',
              name: 'settings',
              component: () => import('@/views/AccountSettingsView.vue'),
              children: [
                {
                  alias: ['/settings'],
                  path: 'profile',
                  name: 'profile',
                  component: () => import('@/views/settings/ProfileView.vue'),
                },
                {
                  path: 'integrations',
                  name: 'integrations',
                  component: () => import('@/views/settings/IntegrationsView.vue'),
                },
                {
                  path: 'integrations/facebook',
                  name: 'integrations/facebook',
                  component: () => import('@/views/settings/FacebookSettingsView.vue'),
                },
                {
                  path: 'integrations/sportradar',
                  name: 'integrations/sportradar',
                  component: () => import('@/views/settings/sportradar/SportradarSettingsView.vue'),
                  meta: {
                    teamOrPlanRequired: true,
                  },
                  // eslint-disable-next-line consistent-return
                  beforeEnter: () => {
                    // Requires at least one sportradar element in the permission check
                    if (!usePermissionsStore().hasSportradar) {
                      return { name: 'integrations' };
                    }
                  },
                },
                {
                  path: 'team',
                  name: 'team',
                  component: () => import('@/views/settings/TeamView.vue'),
                  meta: {
                    teamOrPlanRequired: true,
                  },
                },
                {
                  path: 'devtools',
                  name: 'devtools',
                  component: () => import('@/views/settings/DeveloperToolsView.vue'),
                  meta: {
                    teamOrPlanRequired: true,
                  },
                },
              ],
              meta: {
                title: 'Settings',
              },
            },
            {
              path: 'analytics',
              name: 'analytics',
              component: () => import('@/views/AnalyticsView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Analytics',
              },
            },
            {
              path: 'analytics/url-tracking-dashboard/:id',
              name: 'url-tracking-dashboard',
              component: () => import('@/views/analytics/TrackingUrlDashboard.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'QR Code Analytics Dashboard',
                section: 'analytics', // fpr getting the nav item active
              },
            },
            {
              path: 'analytics/embed-analytics-dashboard/:id',
              name: 'embed-analytics-dashboard',
              component: () => import('@/views/analytics/EmbedAnalyticsView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Embed Analytics Dashboard',
                section: 'analytics', // fpr getting the nav item active
              },
            },
            {
              path: 'design-library',
              name: 'design-library',
              component: () => import('@/views/DesignLibraryView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Design Library',
              },
            },
            {
              path: 'design-library/directory/:directoryId',
              name: 'design-library-directory',
              component: () => import('@/views/DesignLibraryView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Design Library Directory',
              },
            },
            {
              path: 'templates',
              name: 'templates',
              component: () => import('@/views/TemplatesView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Templates',
              },
            },
            {
              path: 'templates/graphics',
              name: 'graphic-templates',
              component: () => import('@/views/GraphicTemplatesView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Graphic Templates',
              },
            },
            {
              path: 'templates/interactive',
              name: 'interactive-templates',
              component: () => import('@/views/InteractiveTemplatesView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Interactive Templates',
              },
            },
            {
              path: 'snippets',
              name: 'snippets',
              component: () => import('@/views/SnippetsView.vue'),
              meta: {
                staffOnly: true,
                title: 'Snippets',
              },
            },
            {
              path: 'snippet/:snippetId',
              name: 'snippet-editor',
              component: () => import('@/views/SnippetEditorView.vue'),
              meta: {
                staffOnly: true,
                title: 'Snippet Editor',
              },
            },
            {
              path: 'pages',
              name: 'pages-list',
              component: () => import('@/views/PagesListView.vue'),
              meta: {
                teamOrPlanRequired: true,
                title: 'Pages',
              },
            },
            {
              path: 'shoppable',
              name: 'shoppable',
              component: () => import('@/views/ShoppableView.vue'),
              // eslint-disable-next-line consistent-return
              beforeEnter: () => {
                if (!usePermissionsStore().hasShoppable) {
                  return { name: 'dashboard' };
                }
              },
              redirect: {
                name: 'browse-products',
              },
              meta: {
                teamOrPlanRequired: true,
                title: 'Shoppable',
              },
              children: [
                {
                  path: 'browse',
                  name: 'browse-products',
                  component: () => import('@/views/BrowseProductsView.vue'),
                },
                {
                  path: 'saved/:collectionId?',
                  name: 'saved-products',
                  component: () => import('@/views/SavedProductsView.vue'),
                },
              ],
            },
          ],
        },
        {
          path: '/page/:pageId',
          name: 'page-editor',
          component: () => import('@/views/PageEditorView.vue'),
          meta: {
            title: 'Page Editor',
          },
        },
      ],
    },
    {
      path: '/signin',
      name: 'signin',
      beforeEnter: (to) => {
        // eslint-disable-next-line max-len, vue/max-len
        window.location.href = `https://user.${BASE_DOMAIN}/auth/auth0?returnUrl=${window.location.origin}${to.query.redirect || '/'}`;
      },
    },
    {
      path: '/email-change/:emailKey',
      name: 'email-change',
      component: () => import('@/views/EmailChange.vue'),
    },
    {
      path: '/invite/:key',
      name: 'invite',
      component: () => import('@/views/InviteProcessingView.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/invite/:key/confirm',
      name: 'invite-signin',
      component: () => import('@/views/InviteConfirmView.vue'),
    },
    {
      path: '/invite-expired',
      name: 'invite-expired',
      meta: {
        requiresAuth: false, // Explicitly state no auth is required
      },
      component: () => import('@/views/InviteExpiredView.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: () => {
        useUserStore().logout();
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/views/NotFound.vue'),
    },
  ],
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from) => {
  const userStore = useUserStore();

  // redirect the user to the login page
  if (to.meta.requiresAuth && !userStore.isAuthenticated) {
    try {
      await userStore.loadUser();
      if (!userStore.isStaff
        && !NODE_ENV === 'stage'
        && !NODE_ENV === 'development'
      ) { // exclude staff, staging, and dev users from Userpilot
        Userpilot.identify(userStore.userId, {
          email: userStore.user.profile.email,
          name: userStore.user.profile.displayName,
        });
      }
    } catch (error) {
      console.error('Failed to load user:', error); // eslint-disable-line no-console
      return { name: 'signin', query: { redirect: to.fullPath } };
    }
  }

  if (to.meta.staffOnly && !userStore.isStaff) {
    // Send user to where they were if they are not staff
    return from;
  }

  if (to.meta.teamOrPlanRequired && userStore.isFreeAccountNoTeam) {
    // Send user to welcome upsell if they don't have a team or plan
    return { name: 'welcome' };
  }

  // preserve hideNavigation query param (like showing content view in a modal via iframe)
  if (from.query.hideNavigation) {
    // eslint-disable-next-line no-param-reassign
    to.query = { ...to.query, hideNavigation: true };
  }

  // restore query params from session storage
  if (to.meta.preserveQuery) {
    const { useHash } = to.meta.preserveQuery;
    const storageKey = useHash ? `${to.name}-${to.hash}` : to.name;
    // only restore query params if the destination route has none
    // this prevents query params from being overwritten when navigating from a saved/shared link
    if (!Object.keys(to.query).length && sessionStore.value[storageKey]?.query) {
      return { ...to, query: sessionStore.value[storageKey]?.query };
    }
  }
});

router.afterEach((to, from) => {
  Userpilot.reload();

  const DEFAULT_TITLE = 'Tagboard';
  document.title = to.meta.title || DEFAULT_TITLE;
  // save selected query params to session storage
  if (from.meta.preserveQuery) {
    let queryParams = from.query;

    // still on same page, get the updated query params
    if (to.name === from.name && to.hash === from.hash) {
      queryParams = to.query;
    }

    const { useHash, params } = from.meta.preserveQuery;
    const storageKey = useHash ? `${from.name}-${from.hash}` : from.name;

    const picked = pickBy(queryParams, (value, key) => {
      if (params.includes(key)) {
        if (Array.isArray(value)) {
          return value.length;
        }

        return true;
      }

      return false;
    });

    if (!Object.keys(picked).length) {
      if ('query' in (sessionStore.value?.[storageKey] || {})) {
        delete sessionStore.value[storageKey].query;
      }
    } else {
      sessionStore.value = merge(sessionStore.value, { [storageKey]: { query: picked } });
    }
  }
});

export default router;
